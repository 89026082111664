import React, { useEffect, useMemo, useState } from 'react'
import * as styles from './checkoutModal.module.scss'
import { Row, Col } from 'react-grid-system'

import * as yup from 'yup'

import { Button, LoadingOverlay, Modal, Svg } from '../common'

import {
   useActiveLocations,
   useForm,
   useMassTransferConnections,
   useSettings,
   useWorkflow,
} from '../../hooks'
import {
   convertConnectionsToOptions,
   convertLocationToOptions,
} from '../common/filters/helpers'
import { LoanAndFieldTransferView } from './checkoutModalViews/loanAndFieldTransferView'
import { ReturnView } from './checkoutModalViews/returnView'
import { WarehouseTransferView } from './checkoutModalViews/warehouseTransferView'
import { BorrowView } from './checkoutModalViews/borrowView'
import { SuccessView } from './checkoutModalViews/successView'
import { Item, ToolStatus } from '../../types'
import { PATH } from '../../constants/global'
import { formatUsDate } from '../../utils/functions'
import { exportToExcel, generateExcelData } from '../views/actions/helpers'
import {
   CartOptions,
   CheckoutModalProps,
   ConnectionRequestType,
   View,
} from './actionsWorkflow.type'
import { ErrorView } from './checkoutModalViews/errorView'

const errorMsg = 'This field is required'

const loanAndFieldTransferSchema = yup.object().shape({
   Connection: yup.string().required(errorMsg),
})
const returnSchema = yup.object().shape({
   Return: yup.string().required(),
})
const warehouseTransferSchema = yup.object().shape({
   NewLocation: yup.string().required(errorMsg),
})
const borrowSchema = yup.object().shape({
   NewLocation: yup.string(),
})
const borrowNoLocationSchema = yup.object().shape({
   DueBackDate: yup.string(),
})
const availableSchema = yup.object().shape({
   Available: yup.string(),
})

const cartOptionsForm: CartOptions = {
   connection: null,
   dueBackDate: '',
   location: '',
   selectedLocationId: null,
}

export const CheckoutModal = ({
   isModalOpen,
   handleClose,
   checkoutAction,
   selectedItems,
   maxWidth,
   zIndex,
   viewTitle,
   handlePageCancelAndReset,
   isAdminCompanyUser,
   quantityInCart,
   handleWorkflowSuccess,
}: CheckoutModalProps) => {
   const {
      postWorkflowRequest,
      isWorkflowPostSuccess,
      isPostingRequest,
      workflowResponse,
      isWorkflowError,
   } = useWorkflow()
   const { settings } = useSettings()

   const [view, setView] = useState<string>(checkoutAction?.label)
   const [modalMsg, setModalMsg] = useState<string | JSX.Element>('')
   const [confirmButtonMsg, setConfirmButtonMsg] = useState<string>('')
   const [validationSchema, setValidationSchema] =
      useState<yup.AnyObjectSchema>(loanAndFieldTransferSchema)
   const [cartOptions, setCartOptions] = useState<CartOptions>(cartOptionsForm)

   useEffect(() => {
      if (checkoutAction?.label) {
         setView(checkoutAction?.label)
      }
   }, [checkoutAction])

   useEffect(() => {
      if (handleWorkflowSuccess && !isWorkflowError) {
         handleWorkflowSuccess(workflowResponse)
      }
   }, [isWorkflowPostSuccess, isWorkflowError])

   const itemsMessage = useMemo(() => {
      const numberOfItems = quantityInCart ?? selectedItems[0]?.quantityForCart
      return numberOfItems === 1 ? 'this item' : `these ${numberOfItems} items`
   }, [selectedItems, quantityInCart])

   useEffect(() => {
      switch (view) {
         case View.Loan:
            const loanMsg = settings.EnableLocationTracking
               ? `You're almost done! Select where to Loan ${itemsMessage}, select a Connection, Location, and a Due Back date if needed.`
               : `You're almost done! Select where to Loan ${itemsMessage}, select a Connection, and a Due Back date if needed.`
            setModalMsg(loanMsg)
            setConfirmButtonMsg('Loan')
            setValidationSchema(loanAndFieldTransferSchema)
            break
         case View.Return:
            setModalMsg('')
            setConfirmButtonMsg('Confirm')
            setValidationSchema(returnSchema)
            break
         case View.Field_Transfer:
            setModalMsg(
               `You're almost done! To Transfer ${itemsMessage}, select a Due Back date and Location if needed.`
            )
            setConfirmButtonMsg('Transfer')
            setValidationSchema(loanAndFieldTransferSchema)
            break
         case View.Warehouse_Transfer:
            setModalMsg(
               `You're almost done! To Transfer ${itemsMessage}, select a Location.`
            )
            setConfirmButtonMsg('Transfer')
            setValidationSchema(warehouseTransferSchema)
            break
         case View.Borrow:
            const borrowMsg = settings.EnableLocationTracking
               ? `You're almost done! To Borrow ${itemsMessage}, select a new Location and Due Back date if needed.`
               : `You're almost done! To Borrow ${itemsMessage}, select a Due Back date if needed.`
            const valSchema = settings.EnableLocationTracking
               ? borrowSchema
               : borrowNoLocationSchema
            setModalMsg(borrowMsg)
            setConfirmButtonMsg('Borrow')
            setValidationSchema(valSchema)
            break
         case View.MakeUnavailable:
         case View.MakeAvailable:
            const availableMsg =
               view === View.MakeAvailable ? (
                  `Are you sure you wish to make ${itemsMessage} Available?`
               ) : (
                  <>
                     <p>
                        Are you sure you wish to make {itemsMessage}
                        &nbsp;Unavailable?
                     </p>
                     <p>
                        When unavailable the item cannot be viewed by employees
                        and the item cannot be loaned or transferred.
                     </p>
                  </>
               )
            setModalMsg(availableMsg)
            setConfirmButtonMsg('Confirm')
            setValidationSchema(availableSchema)
            break
         default:
            return
      }
   }, [view])

   const prepareDataForAPI = (items: Item[], cartOptions: CartOptions) => {
      switch (view) {
         case View.Loan:
         case View.Borrow:
            return {
               postData: {
                  returnDate: cartOptions?.dueBackDate,
                  toolLoanIdAndQuantity: [
                     ...items.map((item) => ({
                        toolId: item.Id,
                        quantity: item?.quantityForCart ?? 1,
                     })),
                  ],
                  borrowingUserId: cartOptions?.connection,
                  location: cartOptions?.location,
                  selectedLocationId: cartOptions?.selectedLocationId,
               },
               path: PATH.WORKFLOW.LOAN_BORROW,
            }
         case View.Return: {
            return {
               postData: {
                  toolLoanIdAndQuantity: [
                     ...items.map((item) => ({
                        toolId: item.Id,
                        quantity: item?.quantityForCart ?? 1,
                        toolLoanId: item?.ToolLoanId,
                     })),
                  ],
               },
               path: PATH.WORKFLOW.RETURN,
            }
         }
         case View.Warehouse_Transfer: {
            return {
               postData: {
                  location: cartOptions?.location,
                  selectedLocationId: cartOptions?.selectedLocationId,
                  toolLoanIdAndQuantity: [
                     ...items.map((item) => ({ toolId: item.Id })),
                  ],
               },
               path: PATH.WORKFLOW.WAREHOUSE_TRANSFER,
            }
         }
         case View.Field_Transfer: {
            return {
               postData: {
                  returnDate: cartOptions?.dueBackDate,
                  toolLoanIdAndQuantity: [
                     ...items.map((item) => ({
                        toolId: item.Id,
                        quantity: item?.quantityForCart ?? 1,
                        toolLoanId: item?.ToolLoanId,
                     })),
                  ],
                  borrowingUserId: cartOptions?.connection,
                  location: cartOptions?.location,
                  selectedLocationId: cartOptions?.selectedLocationId,
               },
               path: PATH.WORKFLOW.FIELD_TRANSFER,
            }
         }
         case View.MakeAvailable:
         case View.MakeUnavailable: {
            return {
               postData: {
                  Available: view === View.MakeAvailable,
                  ToolIds: [...items.map((item) => item.Id)],
               },
               path: PATH.ITEMS.SET_AVAILABILITY,
            }
         }
      }
   }

   const { onSubmit, validationErrors } = useForm(
      validationSchema,
      (values) => {
         if (values) {
            const { postData, path } = prepareDataForAPI(
               selectedItems,
               cartOptions
            )
            postWorkflowRequest(path, postData)
         }
      }
   )

   const connectionRequestType =
      view === View.Loan
         ? ConnectionRequestType.LoanRequest
         : view === View.Field_Transfer
         ? ConnectionRequestType.TransferRequest
         : null

   const { list: connections } = useMassTransferConnections(
      connectionRequestType
   )
   const { list: activeLocations } = useActiveLocations(
      view === View.Warehouse_Transfer
         ? { warehousesOnly: true }
         : { jobsOnly: true }
   )
   const connectionOptions = convertConnectionsToOptions(connections)
   const locationOptions = convertLocationToOptions(activeLocations)
   // used for completed message
   const [connectionName, setConnectionName] = useState<string>('')
   const [_locationName, setLocationName] = useState<string>('')
   const [dueBackDateString, setDueBackDateString] = useState<string>('')

   const handleDropdownChange = (value: string, option: any) => {
      switch (value) {
         case 'Connection':
            setConnectionName(option?.label)
            setCartOptions((prevCartOptions) => ({
               ...prevCartOptions,
               connection: option?.value,
            }))
            break
         case 'Location':
            setLocationName(option?.label)
            setCartOptions((prevCartOptions) => ({
               ...prevCartOptions,
               location: option?.value,
               selectedLocationId: option?.value,
            }))
            break
         default:
            return
      }
   }

   const handleDateChange = (value: string, date: any) => {
      switch (value) {
         case 'DueBack':
            setDueBackDateString(formatUsDate(date).replaceAll('/', '-'))
            setCartOptions((prevState) => {
               return { ...prevState, dueBackDate: date }
            })
            break
      }
   }

   const handleCloseAfterSuccess = () => {
      handlePageCancelAndReset()
      handleClose()
   }

   const handleExportToExcel = () => {
      const itemsToExport =
         connectionRequestType === ConnectionRequestType.LoanRequest
            ? selectedItems.map((item) => {
                 // this formatting is required to show correct data in excel
                 return {
                    ...item,
                    Status: ToolStatus.LOANED,
                    StatusDesc: 'Loaned',
                    StatusQuantity: item.quantityForCart ?? 1,
                    AssignedToUser: connectionName,
                 }
              })
            : selectedItems
      const excelData = generateExcelData(itemsToExport, settings)
      exportToExcel(excelData)
   }

   const isWorkflowComplete = isWorkflowPostSuccess || isWorkflowError

   const modalTitle = useMemo(() => {
      if (isWorkflowError) {
         return `${view} Failed`
      } else if (isWorkflowPostSuccess) {
         return `${view} Complete`
      } else if (view === View.MakeAvailable || view === View.MakeUnavailable) {
         return view
      } else {
         return viewTitle ? `${view} Options` : 'Cart Options'
      }
   }, [viewTitle, isWorkflowPostSuccess, isWorkflowError])

   return (
      <Modal
         isModalVisible={isModalOpen}
         closeModal={handleClose}
         title={modalTitle}
         maxWidth={maxWidth}
         zIndex={zIndex}
      >
         <form id="checkout-modal" noValidate onSubmit={onSubmit}>
            <div className={styles.modalMessageWrapper}>
               {!isWorkflowComplete && <>{modalMsg}</>}
            </div>

            <section
               className={`${styles.modalBodyWrapper} ${
                  view === View.MakeAvailable || view === View.MakeUnavailable
                     ? styles.smallBodyWrapper
                     : ''
               }`}
            >
               {isPostingRequest && <LoadingOverlay />}
               {(view === View.Loan || view === View.Field_Transfer) &&
                  !isWorkflowComplete && (
                     <LoanAndFieldTransferView
                        validationErrors={validationErrors}
                        handleDropdownChange={handleDropdownChange}
                        connectionOptions={connectionOptions}
                        locationOptions={locationOptions}
                        handleDateChange={handleDateChange}
                        cartOptions={cartOptions}
                        settings={settings}
                     />
                  )}

               {view === View.Return && !isWorkflowComplete && <ReturnView />}

               {view === View.Warehouse_Transfer && !isWorkflowComplete && (
                  <WarehouseTransferView
                     validationErrors={validationErrors}
                     handleDropdownChange={handleDropdownChange}
                     locationOptions={locationOptions}
                  />
               )}

               {view === View.Borrow && !isWorkflowComplete && (
                  <BorrowView
                     handleDropdownChange={handleDropdownChange}
                     locationOptions={locationOptions}
                     handleDateChange={handleDateChange}
                     cartOptions={cartOptions}
                     settings={settings}
                  />
               )}

               {isWorkflowPostSuccess && !isWorkflowError && (
                  <SuccessView
                     itemCount={quantityInCart ?? selectedItems.length}
                     view={view}
                     connectionName={connectionName}
                     dueBackDate={dueBackDateString}
                     isAdminCompanyUser={isAdminCompanyUser}
                  />
               )}

               {isWorkflowError && <ErrorView view={view} />}
            </section>
            <div className={styles.buttonRow}>
               {!isWorkflowComplete && (
                  <Row>
                     <Col xs={12} sm={6} md={6} lg={6}>
                        <div className={styles.buttonWrapper}>
                           <Button
                              variant={'primary'}
                              type="submit"
                              form="checkout-modal"
                           >
                              {confirmButtonMsg}
                           </Button>
                        </div>
                     </Col>
                     <Col xs={12} sm={6} md={6} lg={6}>
                        <div className={styles.buttonWrapper}>
                           <Button
                              variant={'tertiary'}
                              onClick={handleClose}
                              type="button"
                              isReset
                           >
                              Go Back
                           </Button>
                        </div>
                     </Col>
                  </Row>
               )}
               {isWorkflowPostSuccess && !isWorkflowError && (
                  <Row>
                     <Col xs={12} sm={6} md={6} lg={6}>
                        <div className={styles.buttonWrapper}>
                           <Button
                              variant={'primary'}
                              type="button"
                              onClick={handleCloseAfterSuccess}
                              isReset
                           >
                              Close
                           </Button>
                        </div>
                     </Col>
                     <Col xs={12} sm={6} md={6} lg={6}>
                        <div className={styles.buttonWrapper}>
                           <Button
                              variant={'tertiary'}
                              onClick={handleExportToExcel}
                              type="button"
                              isReset
                           >
                              <Svg id={'export-row'} />
                              Export
                           </Button>
                        </div>
                     </Col>
                  </Row>
               )}
               {isWorkflowError && (
                  <Row>
                     <Col xs={12} sm={6} md={6} lg={6}>
                        <div className={styles.buttonWrapper}>
                           <Button
                              variant={'tertiary'}
                              type="button"
                              onClick={handleClose}
                              isReset
                           >
                              Close
                           </Button>
                        </div>
                     </Col>
                  </Row>
               )}
            </div>
         </form>
      </Modal>
   )
}
