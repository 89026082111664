import { PATH } from '../../../constants/global'
import { TABLE, CRUD, Views } from '../../../constants/tables'
import { SortDirection } from '../../../hooks/useFilters/useFilters.type'
import { ToolStatus } from '../../../types'
import * as yup from 'yup'

export const filtersSchema = yup.object().shape({})

export const CRUD_TABLE = {
   extraClassName: 'table-actions',
   params: '?itemsId=',
   modalTitle: ' Items',
   placeholder: ' items',
   addButtonText: 'Add Item',
   modalAddTitle: 'Name',
   key: 'Items',
   basicPath: PATH.ITEMS,
   downloadPath: PATH.ITEMS.DOWNLOAD,
   downloadCsvText: 'Export to Excel',
   header: 'Title',
   exportKey: 'ExportToExcelItem',
   exportModalTitle: 'Dowload Excel File',
   exportPlaceholder: ' items',
   tableTitle: TABLE.TITLE.ITEMS,
   exportPrimaryActionText: 'Export',
   exportSecondaryActionText: 'Cancel',
   exportFileName: 'Items.xlsx',
   defaultActiveValue: {
      skip: 0,
      take: 10,
      query: '',
   },
   defaultActionState: 0,
   defaultSortHeader: {
      sortBy: 'title',
      sortDirection: SortDirection.ASCENDING,
   },
   objectKey: 'name',
   objectKeyTitleCase: 'Name',
   primaryActionText: CRUD.primaryActionText,
   secondaryActionText: CRUD.secondaryActionText,
   primaryDeleteText: CRUD.primaryDeleteText,
   crudPaths: PATH.ITEMS,
   columnHeaders: [
      {
         Header: 'Title',
         accessor: 'Title',
      },
      {
         Header: 'Image',
         accessor: 'PictureUrl',
      },
      {
         Header: 'Manufacturer',
         accessor: 'Manufacturer',
      },
      {
         Header: 'Category',
         accessor: 'Category',
      },
      {
         Header: 'Serial No.',
         accessor: 'SerialNumber',
      },
      {
         Header: 'Assigned to Kit',
         accessor: 'AssignedToKitName',
      },
      {
         Header: 'Barcode',
         accessor: 'Barcode',
      },
      {
         Header: 'Status Desc',
         accessor: 'StatusDesc',
      },
      {
         Header: 'Owner Name',
         accessor: 'OwnerName',
      },
   ],
   initialPayload: {
      skip: 0,
      take: 10,
      query: null,
   },
   radioToolStatus: [
      { label: 'All', status: ToolStatus.ALL },
      { label: 'Available', status: ToolStatus.AVAILABLE },
      { label: 'Pending', status: ToolStatus.PENDING },
      { label: 'Loaned', status: ToolStatus.LOANED },
      { label: 'Unavailable', status: ToolStatus.UNAVAILABLE },
   ],
   radioToolStatusEmployeeUser: [
      { label: 'All', status: ToolStatus.ALL },
      { label: 'Available', status: ToolStatus.AVAILABLE },
      { label: 'Pending', status: ToolStatus.PENDING },
      { label: 'Borrowed', status: ToolStatus.EMPLOYEE_RETURN },
   ],
   displayView: [
      { type: 'grid', view: Views.Tile },
      { type: 'lines', view: Views.Tabular },
      { type: 'location', size: 26, view: Views.Map },
   ],
   values: [
      'Permissions',
      'FromSameEnterprise',
      'DefaultLocation',
      'CurrentLocation',
      'RequestedLocation',
      'RequestedLocationId',
      'LocationID',
      'Id',
      'SerialNumber',
      'Title',
      'Description',
      'Manufacturer',
      'Barcode',
      'ModelNumber',
      'StatusDesc',
      'Status',
      'PictureURL',
      'LastUpdated',
      'Notifications',
      'NoteCount',
      'DueBackDate',
      'AssignedToUser',
      'OwnerName',
      'ToolLoanId',
      'ToolType',
      'ToolTypeDesc',
      'Quantity',
      'StatusQuantity',
      'WebKitQuantity',
      'AssignedToUserID',
      'KitPrimeKey',
      'KitId',
      'KitItemID',
      'KitQuantity',
      'IsKitItem',
      'AssignedToKitName',
      'DefaultLocationID',
      'localUniqueId',
      'CustomText',
      'IsOwner',
      'Category',
      'PurchasePrice',
      'WarrantyDate',
      'IsRentalTool',
      'RentalDate',
      'RentalReturnDate',
      'WebSiteURL',
      'AttachmentUrl',
      'LastAuditDate',
      'LastAuditCondition',
      'LastAuditConditionDesc',
      'Vendor',
      'CustomDate1Value',
      'CustomDate2Value',
      'CustomText1Value',
      'CustomText2Value',
      'CustomURL1Value',
      'CustomURL2Value',
      'CustomRentalRate1',
      'CustomRentalRate2',
      'CustomRentalRate3',
      'CustomRentalRate4',
      'ItemId',
   ],
   downloadRequest: {
      skip: 0,
      take: 10,
      query: null,
   },
   customRateArr: [
      { CustomRentalRate1Enabled: 'CustomRentalRate1Label' },
      { CustomRentalRate2Enabled: 'CustomRentalRate2Label' },
      { CustomRentalRate3Enabled: 'CustomRentalRate3Label' },
      { CustomRentalRate4Enabled: 'CustomRentalRate4Label' },
   ],
   locationTrackingArr: ['CurrentLocation', 'DefaultLocation'],
   auditArr: [
      'LastAuditDate',
      'Last Audit Date',
      'LastAuditConditionDesc',
      'Last Audit Condition',
      'Last GPS Location Name',
   ],
   quantityArr: [
      'Total Quantity',
      'Quantity',
      ' Status Quantity',
      'StatusQuantity',
      'Status',
   ],
   minYear: 1999,
   createDefaultSchema: {
      Title: null,
      Category: 'Unspecified',
   },
   defaultColumnOrder: [
      'PictureURL',
      'Title',
      'ToolType',
      'AssignedToKitName',
      'Quantity',
      'StatusQuantity',
      'StatusDesc',
      'AssignedToUser',
      'Description',
      'Manufacturer',
      'ModelNumber',
      'SerialNumber',
      'Barcode',
      'Category',
      'PurchaseDate',
      'PurchasePrice',
      'TotalValue',
      'Vendor',
      'WarrantyDate',
      'RentalItem',
      'RentalDate',
      'RentalReturnDate',
      'CustomRentalRate1',
      'CustomRentalRate2',
      'CustomRentalRate3',
      'CustomRentalRate4',
      'CustomDate1Value',
      'CustomDate2Value',
      'CustomText1Value',
      'CustomText2Value',
      'CustomURL1Value',
      'CustomURL2Value',
      'WebSiteURL',
      'AttachmentUrl',
      'DefaultLocation',
      'CurrentLocation',
      'DueBackDate',
      'GpsCreatedOn',
      'GPSLocationName',
      'GpsCreatedByName',
      'LastAuditDate',
      'LastAuditConditionDesc',
      'CreatedDate',
      'ItemId',
   ],
}
